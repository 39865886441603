const initialState = {
	BASE_URL: undefined,
	CONTENT_API_URL: undefined,
	USER_AUTH_API_URL: undefined,
	QUOTE_API_URL: undefined,
	OAUTH_API_URL: undefined,
	GOOGLE_API_KEY: undefined,
	GTM_ID: undefined,
	NAV_LOGS_API_URL: undefined,
	ENVIRONMENT: undefined,
	ONE_TRUST_SCRIPT: undefined,
};

export default (envVars = initialState) => {
	return envVars;
};
